<template>
  <div class="button">
    <img class="icon" src="../assets/apple.png" alt="" />
    <p class="appname">{{ downloadStr }}</p>
  </div>
</template>

<script>
export default {
  name: "DownloadButton",
  props: {
    url: String,
  },
  data() {
    return {
      downloadStr: ""
    };
  },
  mounted() {
    var language = window.navigator.language
    this.downloadStr = language.startsWith('zh-') ? "下载" : "Download"
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button {
  display: flex;
  width: 120pt;
  height: 36pt;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  border-radius: 5pt;
}

.icon {
  height: 20pt;
  aspect-ratio: 1;
}

.appname {
  font-size: 14pt;
  text-align: center;
  margin-left: 8pt;
  color: #ffffff;
}
</style>
