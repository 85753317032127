<template>
  <div class="main" v-bind:class="{ dire_right: isRight, 'bg-gray': isRight }">
    <img class="icon" v-bind:src="require('../assets/' + icon)" />
    <div class="desc">
      <p class="appname">{{ appName }}</p>
      <p class="appdesc">{{ appDesc }}</p>
      <DownloadButton @click="download" />
    </div>
  </div>
</template>

<script>
import DownloadButton from "./DownloadButton.vue";
export default {
  name: "AppProductCell",
  props: {
    downloadUrl: String,
    icon: String,
    direction: Number, // 0: left-to-right; 1: right-to-left
    appName: String,
    appDesc: String,
  },
  components: {
    DownloadButton,
  },
  computed: {
    isRight() {
      return this.direction % 2 === 1;
    },
  },
  methods: {
    download() {
      window.open(this.downloadUrl, "_blank");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.main {
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 3%;
  padding-top: 3%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 25%;
}

.dire_right {
  flex-direction: row-reverse;
}

.bg-gray {
  background-color: #FBFBFB;
}

.icon {
  width: 45%;
  background-color: aqua;
}

.desc {
  margin-left: 5%;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.appname {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20pt;
  font-weight: bold;
  text-align: left;
  color: #2c3e50;
}

.appdesc {
  font-size: 14pt;
  text-align: left;
  color: #2c3e50;
}
</style>
