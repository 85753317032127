<template>
  <div class="header">
    <h1>{{ msg }}</h1>
    <h4>{{ slogan }}</h4>
  </div>

  <app-cell v-for="(app, index) in apps" :key="index" :appDesc="app.desc" :appName="app.name" :direction="index"
    :icon="app.icon" :downloadUrl="app.url" />

  <p style="margin-top: 5%">© 2024, flyapps Tech. All Rights Reserved.</p>
  <div class="beian">
    <img class="icon" src="../assets/beian.png" />
    <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2021015129号</a>
  </div>
</template>

<script>
import AppCell from "./AppProductCell.vue";

export default {
  name: "MainPage",
  props: {
    msg: String,
  },
  components: {
    AppCell,
  },
  data() {
    return {
      language: 'en',
      apps: [],
      slogan: "Do Something Different",
    };
  },
  computed: {
  },
  methods: {
    loadData() {
      var lcode = "en"
      if (this.language.startsWith('zh-')) {
        lcode = "cn"
        this.slogan = "来些不同，装点生活"
      }
      const jsonData = require("@/assets/" + lcode + ".json");
      this.apps = jsonData
    }
  },
  mounted() {
    this.language = window.navigator.language
    this.loadData();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  height: 180pt;
}

h4 {
  color: #007aff;
}

.beian {
  padding-top: 0;
  margin: 0;
  height: 24pt;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
  margin-left: 3pt;
}

a:visited {
  color: #000000;
}

a:hover {
  color: #007aff;
}
</style>
